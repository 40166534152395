.scheduleBox {
    background-color: transparent;
    padding: 0 0 0 8px !important;
    margin-bottom: 0 !important;
}

.eventRow {
    min-height: 0 !important;
}

.eventRowLines {
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: 8px;
}

.timelineContent {
    flex: 1;
    padding: 0 !important;
}

.timelineSection {
    min-width: 24px;
    align-items: start;
}

.verticalBar {
    width: 1px !important;
    background-color: #4c5357 !important;
    align-self: start;
    left: 50%;
    transform: translateX(-50%);
}

.verticalBarTop {
    height: 100% !important;
}

.pastVerticalBar {
    background-color: #707579 !important;
}

.verticalBarTopBox {
    display: flex;
    height: 7px;
    align-self: start;
}

.verticalBarTopBoxActive {
    display: flex;
    height: 4px;
    align-self: start;
}

.hidden {
    width: 0 !important;
    background-color: transparent;
}

.dot {
    border-radius: 50%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    padding: 0 !important;
    box-shadow: none !important;
}

.activeDot {
    background: #ffad02 !important;
    box-shadow: 0 0 15px #ffad02 !important;
    width: 17px !important;
    height: 17px !important;
    z-index: 10;
}

.passiveDot {
    background: #4c5357 !important;
    border: 0 !important;
    width: 9px;
    height: 9px;
}

.pastDot {
    background: #ffffff !important;
    border: 1px solid #707579 !important;
    width: 9px !important;
    height: 9px !important;
}

.clickable {
    cursor: pointer;
}

.hidden {
    display: none;
}

.eventSeparator {
    height: 30px;
}

.lineLabel {
    margin-right: 4px;
}

.link {
    text-decoration: none !important;
    color: #0078ab !important;
    margin-left: 8px;
}

.dateDesc {
    margin-top: 8px;
    margin-bottom: 16px;
}
