.additional {
    padding: 8px 0;
}

.additionalInfoIconBox {
    width: 20px;
    margin-right: 10px;
    position: relative;
    display: flex;
}

.additionalInfoIcon {
    margin: auto;
    color: #2c3439;
}

.additionalInfoDesc {
    color: #2c3439 !important;
    vertical-align: middle;
}
