.cancellationScheduleBox {
    position: absolute;
    background: #f5f7f8;
    border-radius: 4.005px;
    padding: 24px 6px 24px 31px;
    width: 350px;
}

.title {
    margin-bottom: 32px;
}

.spinnerContainer {
    width: 350px;
    .spinner {
        display: flex;
        margin: auto;
    }
}
