// Override default variables before the import
$body-bg: #000;

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap";
@import "./styles/brand-colors";
@import "./styles/font-styles";

.innerMargin {
    margin-left: 62px;
    margin-right: 62px;
    margin-top: 20px;
}

.box {
    padding: 24px 31px;
    background-color: white;
    border-radius: 5px;
    width: 632px;
    margin-left: 19px;
    margin-bottom: 19px;
}

.boxTitle {
    font-weight: bolder;
    font-size: 1.5rem;
}

.flex {
    display: flex;
}

.columnFlex {
    display: flex;
    flex-direction: column;
}

.columnReverseFlex {
    display: flex;
    flex-direction: column-reverse;
}

.rowFlex {
    display: flex;
    flex-direction: row;
}

.rowReverseFlex {
    display: flex;
    flex-direction: row-reverse;
}

.test {
    background-color: transparent;
}
